import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { modalState } from "../store/modal/modal.selectors";
import Modal from "../components/Modal";
import Dialog from "../components/Dialog";
import { SetJobTitle, SetCandidate, SetAssessmentType, SetAssessmentList, SetReviewer, SetDeadline } from "../store/assignment/assignment.actions";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const Home = (props) => {


    const dispatch = useDispatch();

    const { isModalOpen, isDialogOpen } = useSelector(modalState.getAllState);

    const element = document.getElementsByTagName('html');
    useEffect(() => {
        if (element && element[0] && element[0].classList) {
            element[0].classList.remove('theme-dark')
        }
    }, [element])

    useEffect(()=>{
        if(isModalOpen===false){
            dispatch(SetJobTitle(''));
            dispatch(SetCandidate(null));
            dispatch(SetAssessmentType(null));
            dispatch(SetAssessmentList(null));
            dispatch(SetReviewer([]));
            dispatch(SetDeadline(''));
        }
    },[isModalOpen])
    
    return (
        <div
        style={{
            display: "flex",
            width: "100vw",
            justifyContent: "center",
            marginTop: "20vh",
        }}
    >
        <AuthGuard module='assessment' permission='view'>
        <Modal />
        </AuthGuard>
        {
            isDialogOpen  && (<Dialog />)
        }

    </div>
    );
};

export default Home;