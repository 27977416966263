export enum actionsTypes {
  SET_JOB_TITLE = "assignment/SET_JOB_TITLE",
  SET_CANDIDATE = "assignment/SET_CANDIDATE",
  SET_ASSESSMENT_TYPE = "assignment/SET_ASSESSMENT_TYPE",
  SET_ASSESSMENT_LIST = "assignment/SET_ASSESSMENT_LIST",
  SET_REVIEWER = "assignment/SET_REVIEWER",
  SET_DEADLINE = "assignment/SET_DEADLINE",
  SET_DURATION = "assignment/SET_DURATION",
  SET_ASSIGNMENT_NAME = "assignment/SET_ASSIGNMENT_NAME",
  RESET_STATE = "assignment/RESET_STATE",
}

export type Actions =
  | SetJobTitle
  | SetCandidate
  | SetAssessmentType
  | SetAssessmentList
  | SetReviewer
  | SetDeadline
  | ResetState
  | SetDuration
  | SetAssignmentName;

export interface SetJobTitle {
  type: actionsTypes.SET_JOB_TITLE;
  payload: string;
}

export interface SetCandidate {
  type: actionsTypes.SET_CANDIDATE;
  payload: any;
}

export interface SetAssessmentType {
  type: actionsTypes.SET_ASSESSMENT_TYPE;
  payload: string;
}

export interface SetAssessmentList {
  type: actionsTypes.SET_ASSESSMENT_LIST;
  payload: number;
}

export interface SetReviewer {
  type: actionsTypes.SET_REVIEWER;
  payload: any;
}

export interface SetDeadline {
  type: actionsTypes.SET_DEADLINE;
  payload: string;
}

export interface SetDuration {
  type: actionsTypes.SET_DURATION;
  payload: string;
}


export interface SetAssignmentName {
  type: actionsTypes.SET_ASSIGNMENT_NAME;
  payload: string;
}

export interface ResetState {
  type: actionsTypes.RESET_STATE;
}