import React, { useRef, useState } from "react";
import useOnClickOutside from "../../hook/common/use-outside-click";
import cn from "classnames";

export interface Option {
  id: string;
  name?: string;
  data?: any;
  label?: string;
}

interface CustomSelectProps {
  options: Option[];
  placeholder?: string;
  selected: any;
  setSelected?: React.Dispatch<(prevState: Option) => Option>;
  label?: string;
  className?: string;
  noBorder?: boolean;
  validate?: boolean;
  tooltip?: boolean;
  tooltipText?: string;
  required?: boolean;
  disabled?:boolean;
}

function CodingSelect({
  options,
  placeholder,
  selected,
  setSelected,
  label,
  className,
  validate,
  tooltip,
  tooltipText,
  required,
  disabled=false
}: CustomSelectProps) {
  const dropdownIcon = require("./../../images/dropDownIconCommon.svg");
  const dropdownIconSelected = require("./../../images/dropDownSelected.svg");

  const help = require("./../../images/help.svg");

  const ref = useRef(null);
  const [active, setActive] = useState(false);

  const selectItem = (i) => {
    setSelected(i);
    setActive(false);
  };

  useOnClickOutside(ref, () => setActive(false));

  const finalClassName = [`Select`];
  if (className) finalClassName.push(className);

  const options_array = options.map((item, key) => (
    <div
      key={key}
      className="customSelect__select_box__options_container__option"
      onClick={() => selectItem(item)}
    >
      <input
        type="radio"
        className="customSelect__select_box__options_container__option__radio"
        id={item?.id?.toString()}
        name="category"

      />
      <label htmlFor={item.name}>{item.name ? item.name : item.label}</label>
    </div>
  ));
  
  const content = options_array.length > 0 ? (
    options_array
  ) : (
    <Loader/>
  );
  
  function openSelect() {
    !disabled && setActive(!active);
  }

  return (
    <div ref={ref} className={finalClassName.join(" ")}>
      <label className="customSelect__label">{label}</label>
      {required && <span className="customSelect__required"> *</span>}
      {
        tooltip && <div className="customSelect__action">
          <div className="customSelect__action__item show">
            <img src={help} alt="eye icon" className="customSelect__actionIcon" />
            <div className="customSelect__action__info">
              <p className="customSelect__tooltipText">{tooltipText}</p>
            </div>
          </div>
        </div>
      }

      <div className="customSelect">
        <div className="customSelect__select_box">
          {active && (
            <div className="customSelect__optionsWrapper">
              <div className="customSelect__select_box__options_container scroll active">
                {content}
              </div>
            </div>
          )}
          <div
            className={cn(
              "customSelect__selected",
              { active: active },
              { error: validate }
            )}
            style={{
              backgroundImage: active
                ? `url(${dropdownIconSelected})`
                : `url(${dropdownIcon})`,
            }}
            onClick={openSelect}
          >
            {!selected && placeholder}
            {selected?.name}
          </div>
          {validate && <div className="error-data-select">Invalid data</div>}
        </div>
      </div>
    </div>
  );
}

export default CodingSelect;

const Loader = () => (
  <div className="loader_container">
    <div className="loader"></div>
  </div>
);