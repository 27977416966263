import { modal as types } from "../../types";
import produce from "immer";

export const initialValue: types.State = {
    isModalOpen: false,
    isDialogOpen: false
};

const reducer = (state = initialValue, action: any) => {
    switch (action.type) {
        case types.actionsTypes.HIDE_MODAL:
            return produce(state, (draft) => {
                draft.isModalOpen = action.payload;
            });

        case types.actionsTypes.SHOW_MODAL:
            return produce(state, (draft) => {
                draft.isModalOpen = action.payload;
            });

        case types.actionsTypes.HIDE_DIALOG:
            return produce(state, (draft) => {
                draft.isDialogOpen = action.payload;
            });

        case types.actionsTypes.SHOW_DIALOG:
            return produce(state, (draft) => {
                draft.isDialogOpen = action.payload;
            });

        default:
            return state;
    }
};

export default reducer;
