import { assignment as types } from "../../types";
import produce from "immer";

const avatar = require("../../images/avatar.png");

export const initialValue: types.State = {
  jobTitle: "",
  candidate: [],
  assessmentType: "",
  assessmentsList: null,
  reviewers: [],
  deadline: "",
  duration:{},
  assignmentName:''
};

const reducer = (state = initialValue, action: any) => {
  switch (action.type) {
    case types.actionsTypes.SET_JOB_TITLE:
      return produce(state, (draft) => {
        draft.jobTitle = action.payload;
      });

    case types.actionsTypes.SET_CANDIDATE:
      return produce(state, (draft) => {
        draft.candidate = action.payload;
      });

    case types.actionsTypes.SET_ASSESSMENT_TYPE:
      return produce(state, (draft) => {
        draft.assessmentType = action.payload;
      });

    case types.actionsTypes.SET_ASSESSMENT_LIST:
      return produce(state, (draft) => {
        draft.assessmentsList = action.payload;
      });

    case types.actionsTypes.SET_REVIEWER:
      return produce(state, (draft) => {
        draft.reviewers = action.payload;
      });

    case types.actionsTypes.SET_DEADLINE:
      return produce(state, (draft) => {
        draft.deadline = action.payload;
      });
    
    case types.actionsTypes.SET_DURATION:
      return produce(state, (draft) => {
        draft.duration = action.payload;
      });

    case types.actionsTypes.SET_ASSIGNMENT_NAME:
      return produce(state, (draft) => {
        draft.assignmentName = action.payload;
      });

    case types.actionsTypes.RESET_STATE:
      return { ...initialValue };
  
    default:
      return state;
  }
};

export default reducer;
