import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import SmallLoader from "./SmallLoader";

import { assessmentTypeList } from "../api/optionsSelect";
import {
  ResetState,
  SetAssessmentList,
  SetAssessmentType,
  SetCandidate,
  SetDeadline,
  SetDuration,
  SetJobTitle,
  SetReviewer,
  SetAssignmentName
} from "../store/assignment/assignment.actions";
import { assignmentSelectors } from "../store/assignment/assignment.selectors";
import { HideModal, ShowDialog } from "../store/modal/modal.actions";

import { MultySelectStyle } from "../styles/MultySelectStyle";
import MultySelect from "./MultySelect";
import CodingSelect from "./_elements/CodingSelect";

import { navigate } from "@reach/router";
import fetchData from "../hook/fetchData";
import postData from "../hook/postData";
import { getEnv } from "@urecruits/api";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_ASSESSMENT, API_RECRUITMENT } = getEnv()

const plus = require("./../images/icon/plus_ic.svg");
const cross = require("./../images/icon/cross.svg");
const removeValue = require("./../images/icon/removeValue.svg");
const upIcon = require("./../images/dropDownSelected.svg");
const downIcon = require("./../images/dropDownIconCommon.svg");
const user = require("./../images/avatar.svg");
const help = require("./../images/help.svg");
const avatar = require("../images/avatar.png");

function Modal() {

  const hasAssessmentPackage = false;
  const { checkUserPermission } = useHasPermission()

  const dispatch = useDispatch();
  const [jobTitledata, setJobTitle] = useState({ count: 0, rows: [] });
  const [deadlineTime, setDeadlineTime] = useState([]);
  const [durationTime, setDurationTime] = useState([]);
  const [assessmentList, setAssessmentList] = useState([]);
  const [domainList, setAllDomainList] = useState<any>([]);
  const [reviewersListOptions, setReviewersListOptions] = useState({
    count: 0,
    rows: []
  });

  const [finalData, setFinalData] = useState([]);
  const [jobId, setJobId] = useState();
  const [questionType, setQuestionType] = useState("");
  const [updatedJobData, setUpdatedJobData] = useState([]);
  const [codingParams, setCodingParams] = useState("");
  const [selectedAssessmentType, setSelectedAssessmentType] = useState("");
  const [createAssessment, setCreateAssessment] = useState(false);
  const [loader, setLoader] = useState(true);
  const [updatedReviewerList, setUpdatedReviewerList] = useState([]);
  const {
    jobTitle,
    candidate,
    assessmentType,
    assessmentsList,
    reviewers,
    deadline,
    duration,
    assignmentName
  } = useSelector(assignmentSelectors.getAllState);
  const [data, setData] = useState();
  const selectedListSelector =
    reviewers && reviewers.length
      ? updatedReviewerList.filter((item) => reviewers.includes(item.id))
      : null;

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);

  const unlock = (
    ((!hasAssessmentPackage && jobTitle) || (hasAssessmentPackage && assignmentName)) &&
    assessmentType &&
    assessmentsList &&
    ((assessmentType !== 'Live Task Assessment' && deadline) || assessmentType === 'Live Task Assessment') &&
    ((assessmentType !== 'Domain Assessment' && duration?.value) || assessmentType === 'Domain Assessment')
  ) ? "" : "disable";

  const Option = (props) => {
    const selectedData = props?.selectProps?.placeholder === "Select candidates" ? candidate : reviewers
    const allData = props?.selectProps?.placeholder === "Select candidates" ? finalData : updatedReviewerList
    return (
      <div className="assignment-modal__multySelectorOption">
        <components.Option {...props}>
          <label className="form-control">
            <input
              type="checkbox"
              className="checkbox-checked"
              checked={
                props.label === "Select all" &&
                  selectedData?.length === allData?.length
                  ? true
                  : props.isSelected
              }
              onChange={() => null}
            />
            {props.label !== "Select all" ? (
              <img
                className="assignment-modal__avatar"
                src={props.data.img}
                alt="cross-icon"
              />
            ) : null}
            <span
              className={
                props.label === "Select all" ? "optionSelectAll" : undefined
              }>
              {props.label}
            </span>
          </label>
        </components.Option>
      </div>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img
            src={props.selectProps.menuIsOpen ? upIcon : downIcon}
            alt="up"
          />
        </components.DropdownIndicator>
      )
    );
  };

  const ClearIndicator = (props) => (
    <components.ClearIndicator {...props}>
      <img className="remove-value-icon" src={cross} alt="cross" />
    </components.ClearIndicator>
  );

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span className="assignment-modal__multyValue">{props.data.label}</span>
    </components.MultiValue>
  );

  const MultiValueRemove = (props) => (
    <components.MultiValueRemove {...props}>
      <img className="remove-icon" src={removeValue} alt="removeValue" />
    </components.MultiValueRemove>
  );

  const selectedCandidateListSelector =
    candidate && candidate.length
      ? finalData?.filter((item) => candidate.includes(item.id))
      : null;

  const handleCandidateChange = (selected) => {
    const ids =
      selected && selected.length ? selected.map((item) => item.id) : null;
    dispatch(SetCandidate(ids));
  };

  const handleChange = (selected) => {
    const ids =
      selected && selected.length ? selected.map((item) => item.id) : null;
    dispatch(SetReviewer(ids));
  };

  const handleCancel = () => {
    dispatch(ResetState());
    navigate("/recruitment/manage-assignment");
  }

  const handleChangeDeadline = (selected) => {
    dispatch(SetDeadline(selected.label));
  };
  const handleChangeDuration = (selected) => {
    dispatch(SetDuration(selected));
  }

  const sendData = () => {
    let data = {}
    const obj = {
      title: jobTitle,
      jobId: jobId,
      candidate: candidate,
      assessmentType,
      reviewers,
      deadline,
      duration: duration?.value,
      name: assignmentName
    };
    const assessment = assessmentList.find((item) => item.id === assessmentsList)

    assessmentType === "Domain Assessment" ? (
      data = { ...obj, domainId: assessmentsList, duration: assessment?.duration || duration?.value }
    ) : assessmentType === "Live Task Assessment" ?
      (
        data = { ...obj, liveCodingId: assessmentsList, assessmentType: 'live-task' }
      ) : assessmentType === "Take Home Task Assessment" && (
        data = { ...obj, takeHomeTaskId: assessmentsList, assessmentType: 'take-home' }
      )

    const api = hasAssessmentPackage ? `${API_RECRUITMENT}/api/job/assignment` : `${API_ASSESSMENT}/api/assignment`
    postData(api, data).then(
      (res) => {
        dispatch(ShowDialog(true));
        dispatch(ResetState());
        return setData(res);
      },
      (err) => {
        console.error(err);
      }
    );
  };
  useEffect(() => {
    checkUserPermission('job-post', 'view') && fetchData(`${API_RECRUITMENT}/api/company/jobs`, setJobTitle).then(
      (res) => res.data
    );
    !hasAssessmentPackage && checkUserPermission('team-members', 'view') && fetchData(
      `${API_RECRUITMENT}/api/company/members`,
      setReviewersListOptions
    ).then((res) => res.data);
    fetchData(
      `${API_ASSESSMENT}/api/assignment/deadline`,
      setDeadlineTime
    ).then((res) => res.data);
    fetchData(
      `${API_ASSESSMENT}/api/assignment/time-duration`,
      () => { }
    ).then((res) => {
      const formattedData = res.data?.map((item) => ({
        value: item.id,
        label: item.timeDuration,
      }));

      formattedData.sort((a, b) => {
        const firstValue = parseInt(a.label.split(" ")[0]);
        const secondValue = parseInt(b.label.split(" ")[0]);
        return firstValue - secondValue;
      });
      setDurationTime(formattedData);
    });
  }, []);


  useEffect(() => {
    if (checkUserPermission('assessment', 'view')) {
      if ((assessmentType === "Live Task Assessment" || assessmentType === "Take Home Task Assessment") && codingParams) {
        fetchData(
          `${API_ASSESSMENT}/api/coding-assessment/search?type=${codingParams}&status=ACTIVE`,
          setAssessmentList
        ).then((res) => res.data);
      } else if (assessmentType === "Domain Assessment") {
        fetchData(
          `${API_ASSESSMENT}/api/domain-questions`,
          setAllDomainList
        )
      } else {
        setAssessmentList([]);
      }
    }
  }, [assessmentType, codingParams]);

  useEffect(() => {
    if (domainList?.rows?.length > 0) {
      setAssessmentList(domainList?.rows);
    }
  }, [domainList])

  useEffect(() => {
    setUpdatedJobData(
      jobTitledata.rows.map((item) => ({
        id: item.id,
        name: item.title,
      }))
    );
  }, [jobTitledata]);



  useEffect(() => {
    const codingAssessment = assessmentTypeList.find(
      (item) => item.name === assessmentType
    );
    if (codingAssessment) {
      setSelectedAssessmentType(codingAssessment.value);
      setCodingParams(
        codingAssessment.value
      );
    }
  }, [assessmentType, questionType]);

  useEffect(() => {
    const users = reviewersListOptions.rows.map((row) => ({
      id: row.user.id,
      value: `${row.user.firstname} ${row.user.lastname}`,
      label: `${row.user.firstname}${row.user.lastname}`,
      img: avatar,
    }));
    setUpdatedReviewerList(users);
  }, [reviewersListOptions]);

  return (
    <AuthGuard module={['job-post', 'assessment']} permission='view' option="AND">
      <AuthGuard module='assessment' permission='add'>
        {createAssessment ? (
          assessmentType === "Domain Assessment" ? (
            navigate("/manage-domain")
          ) : assessmentType === "Live Task Assessment" ? (
            navigate("/coding-assessments/live-task")
          ) : (
            navigate("/coding-assessments/take-home-task")
          )
        ) : (
          <div
            className="assignment-modal"
            onClick={() => dispatch(HideModal(false))}
          >
            <div
              className="assignment-modal__container"
              onClick={(e) => e.stopPropagation()}
            >
              {loader ? (
                <div>
                  <SmallLoader />
                </div>
              ) : (
                <>
                  <div className="assignment-modal__head__wrapper">
                    <div className="assignment-modal__head">
                      <h1 className="assignment-modal__head__title">
                        Create New Assignment
                      </h1>
                      <button
                        className="assignment-modal__head__cross-ico"
                        onClick={() => {
                          dispatch(HideModal(false))
                          handleCancel()
                        }}
                      >
                        <img src={cross} alt="cross-icon" />
                      </button>
                    </div>
                  </div>
                  <h4 className="assignment-modal__subtitle mgTop">
                    Main Information
                  </h4>
                  {hasAssessmentPackage ? <div className="assignment-modal__item">
                    <label className="assignment-modal__label" htmlFor="title">
                      Name<span> *</span>
                    </label>
                    <input
                      className="assignment-modal__item__input"
                      id="title"
                      value={assignmentName}
                      placeholder="Enter Assignment Name"
                      onChange={(e) => {
                        dispatch(SetAssignmentName(e.target.value))
                      }}
                    />
                    {/* {titleError && (
                      <p className="recruitment__popup-item__error-message">
                        This is required field
                      </p>
                    )} */}
                  </div>
                    :
                    <div className="assignment-modal__select">
                      <CodingSelect
                        label="Job Title"
                        required={true}
                        placeholder="Enter job title to assign the assessment"
                        options={updatedJobData as Array<any>}
                        selected={jobTitle && { name: jobTitle }}
                        setSelected={(jobTitle: any) => {
                          dispatch(SetJobTitle(jobTitle.name));
                          setJobId(jobTitle.id);
                        }}
                      />
                    </div>}
                  <h4 className="assignment-modal__subtitle">Assessment</h4>
                  <div className="assignment-modal__select">
                    <CodingSelect
                      label="Type of Assessment"
                      required={true}
                      placeholder="Choose type assessment from database"
                      options={assessmentTypeList as Array<any>}
                      selected={
                        assessmentTypeList.find(
                          (item) => item.name === assessmentType
                        ) as object
                      }
                      setSelected={(assessmentType) =>
                        dispatch(SetAssessmentType(assessmentType.name))
                      }
                    />
                  </div>
                  <div className="assignment-modal__select">
                    <CodingSelect
                      disabled={assessmentTypeList.find((item) => item.name === assessmentType) ? false : true}
                      label="List of Assessment"
                      required={true}
                      placeholder="Choose assessment from database"
                      options={assessmentList as Array<any>}
                      selected={
                        assessmentList.find(
                          (item) => item.id === assessmentsList
                        ) as object
                      }
                      setSelected={(assessmentsList: any) =>
                        dispatch(SetAssessmentList(assessmentsList.id))
                      }
                    />
                  </div>
                  <AuthGuard module='assessment' permission='add'>
                    <p className="assignment-modal__text">or</p>
                    <button
                      className="assignment-modal__assessmentButton"
                      onClick={() => setCreateAssessment(true)}
                    >
                      <img
                        className="assignment-modal__plusIcon"
                        src={plus}
                        alt="cross-icon"
                      />{" "}
                      Create new assessment
                    </button>
                  </AuthGuard>
                  {!hasAssessmentPackage && <AuthGuard module='team-members' permission='view'>
                    <div className="assignment-modal__multySelector">
                      <span className="assignment-modal__label">Reviewer</span>
                      <MultySelect
                        openMenuOnClick={true}
                        options={updatedReviewerList}
                        isMulti={true}
                        menuPlacement="top"
                        placeholder="Select reviewer"
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          MultiValue,
                          MultiValueRemove,
                          ClearIndicator,
                          DropdownIndicator,
                          IndicatorSeparator: () => null,
                        }}
                        onChange={handleChange}
                        allowSelectAll={true}
                        value={selectedListSelector}
                        styles={MultySelectStyle}
                      />
                    </div>
                  </AuthGuard>}
                  {assessmentType !== 'Live Task Assessment' && <div className="assignment-modal__select">
                    <span className="assignment-modal__label">
                      Deadline <span className="customSelect__required"> *</span>
                      <div className="customSelect__action">
                        <div className="customSelect__action__item show">
                          <img
                            src={help}
                            alt="eye icon"
                            className="customSelect__actionIcon"
                          />
                          <div className="customSelect__action__info">
                            <p className="customSelect__tooltipText">
                              {
                                "By what day a candidate has to complete the assessment"
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </span>
                    <MultySelect
                      openMenuOnClick={true}
                      options={deadlineTime.map((item) => ({
                        value: item.time,
                        label: item.time,
                      }))}
                      menuPlacement="top"
                      placeholder="Select deadline"
                      hideSelectedOptions={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={handleChangeDeadline}
                      value={
                        deadlineTime.find((item) => item.time === deadline)
                          ? { value: deadline, label: deadline }
                          : null
                      }
                      styles={MultySelectStyle}
                    />
                  </div>
                  }
                  {assessmentType !== "Domain Assessment" && <div className="assignment-modal__select">
                    <span className="assignment-modal__label">
                      Time Duration <span className="customSelect__required"> *</span>
                      <div className="customSelect__action">
                        <div className="customSelect__action__item show">
                          <img
                            src={help}
                            alt="eye icon"
                            className="customSelect__actionIcon"
                          />
                          <div className="customSelect__action__info">
                            <p className="customSelect__tooltipText">
                              {
                                "How much time you want to give to the candidate to solve a problem or take a test"
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </span>
                    <MultySelect
                      openMenuOnClick={true}
                      options={durationTime}
                      menuPlacement="top"
                      placeholder="Select time duration"
                      hideSelectedOptions={false}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={handleChangeDuration}
                      value={duration?.value ? duration : null}
                      styles={MultySelectStyle}
                    />
                  </div>}
                  <div className="assignment-modal__footer">
                    <div
                      className="assignment-modal__footer__container"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <button
                        className="assignment-modal__footer__container__cancel"
                        onClick={() => {
                          dispatch(HideModal(false))
                          handleCancel()
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={unlock === "disable" ? true : false}
                        className={`assignment-modal__footer__container__save ${unlock}`}
                        onClick={() => sendData()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </AuthGuard>
    </AuthGuard>
  );
}

export default Modal;
