import React from "react";
import { useHasPermission } from "./useHasPermission";
import { modulesType, permissionType } from "../../context/types";

export const AuthGuard = ({
  children,
  module,
  permission = "",
  option = ""
}: {
  children: React.ReactNode;
  module: modulesType | modulesType[];
  permission?: permissionType | permissionType[] | "" ;
  option?: 'AND' | "" ;
}) => {
  const {checkUserPermission} = useHasPermission();
  const hasPermission = checkUserPermission(module, permission, option);

  if (hasPermission) {
    return <>{children}</>;
  }

  return <></>;
};
