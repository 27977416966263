
const UnauthorizePage = () => {
  return (
    <div className='fallback__page'>
      <p>Access is not authorized. Please contact the administrator to enable your access to this module.</p>      
    </div>
  )
}

export default UnauthorizePage
