export const MultySelectStyle = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        border: '1px solid hsl(0, 0%, 80%)',
        boxShadow: 'none',
        fontFamily: 'Avenir LT Std, sans-serif',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        '&:hover': {
            border: '1px solid hsl(0, 0%, 80%)'
        }
    }),
    option: (styles,) => {
        return {
            ...styles,
            fontSize: "14px",
            fontFamily: 'Avenir LT Std, sans-serif',
            fontWeight: '500',
            backgroundColor: 'transparent',
            color: "#2A2C33",
        };
    },
    placeholder: (styles,) => {
        return {
            ...styles,
            fontSize: "14px",
            backgroundColor: 'transparent',
            color: "#111",
            fontFamily: 'Avenir LT Std, sans-serif',
            fontStyle: 'normal',
            fontWeight: '500',
        };
    },
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: "rgba(2, 156, 165, 0.1)",
        color: 'rgba(42, 44, 51, 1)',
        borderRadius: 3,
        fontStyle: 'normal',
    }),
    menuList: (base) => ({
        ...base,
        marginTop: "0px",
        backgroundColor: '#fff',
        "::-webkit-scrollbar": {
            width: "4px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#fff"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#029CA5",
            borderRadius: "4px"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#029CA5"
        }
    }),
    menu: (base) => ({
        ...base,
        marginTop: "0px",
        marginBottom: "0px",
    })
};
