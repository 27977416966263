const avatar = require("../images/avatar.png");
const avatar1 = require("../images/avatar1.png");
const avatar2 = require("../images/avatar2.png");

const jobList = [
  { name: "Angular Developer" },
  { name: "Vue Developer" },
  { name: "React Developer" },
];

const candidateList = [
  { id: 1, name: "Alex Walling" },
  { id: 2, name: "Jane Dou" },
  { id: 3, name: "John Dou" },
];

const assessmentTypeList = [
  { name: "Domain Assessment" , value:"domain-assessment"},
  { name: "Live Task Assessment", value:"live-task" },
  { name: "Take Home Task Assessment", value:"take-home" },
  { name: "Third Party Assessment",value:"third-party-assessment" },
];


const questionTypeList = [
  { name: "Live Task" , value:"live-task"},
  { name: "Take Home Task", value:"take-home" },
];

const assessmentsOptionList = [
  { id: 1, name: "Business Analyst Functional Assessments" },
  { id: 2, name: "IOS Developer Functional Assessments" },
  { id: 3, name: "MarkUp Developer Functional Assessments" },
  { id: 4, name: "Sales Manager Functional Assessments" },
  { id: 5, name: "Android Developer Functional Assessments" },
  { id: 6, name: "UI/UX Designer Functional Assessments" },
];

const reviewersList = [
  { id: 1, value: "Jenny Wilson", label: "Jenny Wilson", img: avatar },
  { id: 2, value: "Savannah Nguyen", label: "Savannah Nguyen", img: avatar1 },
  { id: 3, value: "Bessie Cooper", label: "Bessie Cooper", img: avatar2 },
  { id: 4, value: "Jane Dou", label: "Jane Dou", img: avatar },
  { id: 5, value: "John Dou", label: "John Dou", img: avatar1 },
  { id: 6, value: "yellow", label: "Yellow", img: avatar2 },
  { id: 7, value: "green", label: "Green", img: avatar },
  { id: 8, value: "forest", label: "Forest", img: avatar1 },
  { id: 9, value: "slate", label: "Slate", img: avatar2 },
  { id: 10, value: "silver", label: "Silver", img: avatar },
];

const deadlineList = [
  { value: "12 Hrs", label: "12 Hrs", },
  { value: "24 Hrs", label: "24 Hrs", },
  { value: "48 Hrs", label: "48 Hrs", },
  { value: "72 Hrs", label: "72 Hrs", },
  { value: "96 Hrs", label: "96 Hrs", },
  { value: "120 Hrs", label: "120 Hrs", },
  { value: "144 Hrs", label: "144 Hrs", },
];

export {
  assessmentTypeList,
  assessmentsOptionList,
  candidateList,
  jobList,
  reviewersList,
  deadlineList,
  questionTypeList
};
