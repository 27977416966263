import { createContext, Dispatch, SetStateAction } from "react";

export interface AuthContextType {
  permissions: string[];
  setPermissions: Dispatch<SetStateAction<string[]>>;
  userId: number;
  setUserId: Dispatch<SetStateAction<number>>;
  companyId: number;
  setCompanyId: Dispatch<SetStateAction<number>>;
}

export const AuthContext = createContext({
  permissions: [],
  setPermissions: () => {},
  userId: 0,
  setUserId: () => {},
  companyId: 0,
  setCompanyId: () => {}
} as AuthContextType);
