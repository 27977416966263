import { combineReducers } from "redux";
import assignment from "./assignment/assignment.reducer";
import modal from "./modal/modal.reducer";
import * as types from "../types";

export interface RootState {
  assignment: types.assignment.State;
  modal: types.modal.State;
}

export interface RootAction {
  type: "RESET_APP";
}

const appReducer = combineReducers<RootState>({
  assignment,
  modal
});

const rootReducer = (
  state: RootState | undefined,
  action: RootAction
): RootState => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
