export enum actionsTypes {
    SHOW_MODAL = "modal/SHOW_MODAL",
    HIDE_MODAL = "modal/HIDE_MODAL",
    SHOW_DIALOG = "modal/SHOW_DIALOG",
    HIDE_DIALOG = "modal/HIDE_DIALOG",
}

export type Actions =
    | HideModal
    | ShowModal

export interface HideModal {
    type: actionsTypes.HIDE_MODAL;
    payload: boolean;
}

export interface ShowModal {
    type: actionsTypes.SHOW_MODAL;
    payload: boolean;
}

export interface HideDialog {
    type: actionsTypes.HIDE_DIALOG;
    payload: boolean;
}

export interface ShowDialog {
    type: actionsTypes.SHOW_DIALOG;
    payload: boolean;
}