import { useEffect, useState } from "react";
import axios from "axios";

export const useHasSubscription = () => {

	const token: string | null = localStorage.getItem("token");
	const config = token ? {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	} : {};

	const [isLoadingSubscription, setIsLoadingSubscription] = useState<boolean>(false);
	const [subscriptionData, setSubscriptionData] = useState<any>(null);

	useEffect(() => {
		setIsLoadingSubscription(true);
		subscriptionHandler();
	}, []);

	const subscriptionHandler = () => {

		const getData = async () => {
			try {
				await axios(`https://recruitment-micro.urecruits.com/api/subscription/get-company-main-subscription`, config)
					.then((res) => {
						if (!!res.data && Object.keys(res.data).length > 0) {
							setSubscriptionData(res.data);
						}
						setIsLoadingSubscription(false);
					});
			} catch (err) {
				setIsLoadingSubscription(false);
				
			}

		};
		getData().then();
	};

	const checkCompanySubscription = () => {
		if(!isLoadingSubscription && subscriptionData && subscriptionData.subId != "noSubscription"){
			return true
		}
		return false
	};

	const hasCompanySubscriptionElement = (element) => {
		const isCompanySubscription = checkCompanySubscription();
		if(isCompanySubscription){
			return element;
		}
		if(isLoadingSubscription){
			return <h1>Loading...</h1> 
		}  
		return <h1>No subscription</h1>
	};

	return {
		subscriptionData,
		isLoadingSubscription,
		setIsLoadingSubscription,
		checkCompanySubscription,
		hasCompanySubscriptionElement,
	}
};

