import React from "react";
import { default as ReactSelect } from "react-select";

export interface Option {
  value: string;
  label: string;
}

interface CustomSelectProps {
  options?: Option[];
  placeholder?: string;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  components?: any;
  onChange: any;
  allowSelectAll?: boolean;
  value: any;
  allOption: any;
  openMenuOnClick?: boolean;
  styles?: any;
  menuPlacement?: any;
  tooltip?: any;
  tooltipText?: any;
}

const MySelect = (props: CustomSelectProps) => {
  const { options, allowSelectAll, allOption } = props;

  const sortedOptions = options ? [...options].sort((a, b) => a.label.localeCompare(b.label)) : [];

  if (allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={[props.isMulti ? allOption : null, ...sortedOptions]}
        onChange={(selected) => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === allOption.value
          ) {
            return props.onChange(options);
          }
          return props.onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect {...props} options={sortedOptions} />;
};

MySelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default MySelect;
