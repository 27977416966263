import {memo} from "react";
import {ICommonTheadItem} from "../../types/table-types";
const filterIcon = require("../../images/icons/filter-arrows.svg");

const CommonTheadItem = ({ title, handler, field }: ICommonTheadItem) => {
	return (
		handler?
		<div className='table__thead__wrapper clickable'  onClick={()=>handler(field)} role='button'>
			<p className="table__thead__title">{title}</p>
		 <img src={filterIcon} alt="filter-icon" className="table__thead__icon"/>
		</div>
			:
			<div className='table__thead__wrapper' >
				<p className="table__thead__title">{title}</p>
			</div>
	)
}

export default memo(CommonTheadItem)