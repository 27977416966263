import { assignment } from "../../types";
import { RootState } from "../root-reducer";

const getAllState = (state: RootState): assignment.State => {
  return state.assignment;
};

export const assignmentSelectors = {
  getAllState,
};
