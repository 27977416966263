import {memo} from "react";
import {ITheadItem} from "../../types/table-types";
const filterIcon = require("../../images/icons/filter-arrows.svg");

const TheadItem = ({title,handler,dbName}: ITheadItem) => {
	return (
		handler?
		<div className='table__thead__wrapper clickable'  onClick={()=>handler(dbName)} role='button' tabIndex={0}>
			<p className="table__thead__title">{title}</p>
		 <img src={filterIcon} alt="filter-icon" className="table__thead__icon"/>
		</div>
			:
			<div className='table__thead__wrapper' >
				<p className="table__thead__title">{title}</p>
			</div>
	)
}

export default memo(TheadItem)