import { useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { decodeToken } from "../../utils/utils";

export const AuthProvider = ({ children }) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [userId, setUserId] = useState<number>(0);
  const [companyId, setCompanyId] = useState<number>(0);

  useEffect(() => {
    decodeToken().then((data) => {
      setPermissions(data?.permissions || []);
      setUserId(data['https://urecruits.com/userId']? data['https://urecruits.com/userId'] : 0);
      setCompanyId(data['https://urecruits.com/companyId']? data['https://urecruits.com/companyId'] : 0);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ permissions, setPermissions, userId, setUserId, companyId, setCompanyId }}>
      {children}
    </AuthContext.Provider>
  );
};
