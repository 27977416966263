
const NotFoundPage = () => {
  return (
    <div className='fallback__page'>
      <p>404: Page not found.</p>      
    </div>
  )
}

export default NotFoundPage
