import { modal } from "../../types";
import { RootState } from "../root-reducer";

const getAllState = (state: RootState): modal.State => {
    return state.modal;
};

export const modalState = {
    getAllState,
};
