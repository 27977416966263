import { useContext } from "react";
import { AuthContext, AuthContextType } from "../../context/authContext";

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("Can not use useAuth Outside <AuthProvider />");
  }

  return context;
};
