import {memo} from "react";
import "./_checkbox.scss";
import {ICheckBox} from "../../types/table-types";
const doneIcon = require('../../images/icons/small_done_ic.svg');

const CheckBox = ({status = false, setStatus, addClass = null}: ICheckBox) => {
	return (
		<div onClick={() => setStatus(!status)} className={`checkbox${status ? ' active' : ''} ${addClass ? addClass : ''}`}>
			<label className="checkbox__label">
				<img src={doneIcon} alt="checkbox" className="checkbox__icon"/>
			</label>
		</div>
	)
}

export default memo(CheckBox)