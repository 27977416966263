import jwtDecode from "jwt-decode";

export async function decodeToken() {
  const token: any = await getToken();
  return jwtDecode<any>(token);
}

async function getToken() {
  const timeout = () => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const res = localStorage.getItem("token");
        if (!res) {
          resolve(timeout());
        } else {
          resolve(res);
        }
      }, 1000);
    });
  };
  const token: string = localStorage.getItem("token") || "";
  if (token) {
    return token;
  } else {
    return await timeout();
  }
}
